export default class DvlaErrors {
    constructor(errors) {
        this.errors = errors;
    }

    includes(attribute) {
        if (this.errors === null || this.errors === undefined) {
            return false;
        }
        if (
            Object.keys(this.errors).includes(attribute) &&
            this.errors[attribute] instanceof Array &&
            this.errors[attribute].length > 0
        ) {
            return true;
        }
        return false;
    }

    messagesFor(attribute) {
        if (this.errors === null || this.errors === undefined) {
            return [];
        }
        if (this.includes(attribute)) {
            return this.errors[attribute];
        }
        return [];
    }
}
