import React from 'react';

export default function OrderSummaryBox(props) {
    return (
        <div className="payment-total">
            <h2 className="govuk-heading-m">Order summary</h2>
            <div className="payment-items govuk-body">
                <span id="quantityPlayback">
                    {props.validatedPurchaseVolume}
                </span>
                {props.validatedPurchaseVolume === '1'
                    ? 'x company tachograph card'
                    : 'x company tachograph cards'}
                <span id="plural" />{' '}
                <i id="renewReplaceLabel" style={{ display: 'none' }}>
                    (<span id="renewOrReplace" />; £19)
                </i>
            </div>
            <div className="govuk-body">Amount to pay</div>
            <div id="cost" className="payment-cost payment-actual govuk-body">
                &pound;{props.runningTotal}
            </div>
        </div>
    );
}
