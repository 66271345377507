import React from 'react';
import PropTypes from 'prop-types';
import MAX_CARDS from './shared/Constants';
import OrderSummary from './shared/OrderSummaryBox';
import DvlaErrors from '../validation/DvlaErrors';

export default class CardsPage extends React.Component {
    static isInteger(n) {
        return n % 1 === 0;
    }

    static convertPennysToPounds(penny) {
        return (penny / 100).toFixed(2).toLocaleString();
    }

    constructor(props) {
        super(props);

        this.errors = new DvlaErrors(this.props.presenter.errors);
        this.timeout = 0;
        this.state = {
            numCards: this.props.presenter.numCards,
            runningTotal: CardsPage.convertPennysToPounds(
                this.props.presenter.transactionCost
            ),
            validatedTachoDisplayNum: 1,
            screenReaderLabelIsSet: false,
        };

        this.handleNewTotal = this.handleNewTotal.bind(this);
    }

    componentDidMount() {
        const stateUpdates = {};
        if (this.props.presenter.numCards) {
            stateUpdates.numCards = this.props.presenter.numCards;
        }
        this.setState(stateUpdates);
        this.greySummaryBoxCalculator(this.props.presenter.numCards);
    }

    handleNewTotal(event) {
        const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
        const { paramName } = this.props.presenter;
        const scope = this;

        const focusForScreenReader = () => {
            const refocus = () => {
                document.getElementById(`${paramName}_num_cards`).focus();
            };
            if (isFirefox) {
                document.getElementById(`${paramName}_num_cards`).blur();
            } else {
                document.getElementById('calculating').focus();
            }
            setTimeout(refocus, 200);
            scope.setState(
                {
                    screenReaderLabelIsSet: false,
                    calculating: false,
                },
                () => scope.setState({ screenReaderLabelIsSet: true })
            );
        };

        this.setState(
            {
                numCards: event.target.value,
                screenReaderLabelIsSet: true,
                calculating: true,
            },
            () => {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(focusForScreenReader, 2500);
            }
        );
        this.greySummaryBoxCalculator(event.target.value);
    }

    greySummaryBoxCalculator(value) {
        if (value <= MAX_CARDS && value > 0 && CardsPage.isInteger(value)) {
            this.setState({
                runningTotal: CardsPage.convertPennysToPounds(
                    value * this.props.presenter.transactionCost
                ),
                validatedTachoDisplayNum: value,
            });
        } else {
            this.setState({
                runningTotal: 0,
                validatedTachoDisplayNum: 0,
            });
        }
    }

    screenReaderToDicateOrderSummaryBoxText() {
        const emptyInput =
            this.props.presenter.question == null
                ? 'How many company tachograph cards do you require?'
                : this.props.presenter.question;

        const updating = 'Calculating';

        const content = `You require ${
            this.state.numCards
        } Tachograph company card${
            this.state.numCards === '1' ? '' : 's'
        } and the Amount to pay is £${this.state.runningTotal}`;
        if (
            this.state.numCards === '' ||
            this.state.screenReaderLabelIsSet === false
        ) {
            if (this.state.runningTotal === '') {
                return emptyInput;
            }
            return `${emptyInput}. ${content}`;
        }
        if (this.state.calculating) {
            return updating;
        }
        return content;
    }

    render() {
        return (
            <div className="govuk-grid-row">
                <div className="govuk-grid-column-two-thirds">
                    <form action={this.props.presenter.action} method="post">
                        <div
                            className={
                                this.errors.includes('num_cards')
                                    ? 'govuk-form-group govuk-form-group--error'
                                    : 'govuk-form-group'
                            }
                        >
                            <h1 className="govuk-label-wrapper">
                                <label
                                    className="govuk-label govuk-label--l"
                                    htmlFor={`${this.props.presenter.paramName}_num_cards`}
                                >
                                    {this.props.presenter.question === null
                                        ? 'How many company tachograph cards do you require?'
                                        : this.props.presenter.question}
                                </label>
                                <div
                                    className="govuk-hint"
                                    dangerouslySetInnerHTML={
                                        this.props.presenter.questionHint
                                    }
                                />
                            </h1>
                            {this.errors
                                .messagesFor('num_cards')
                                .filter((element, index) => index === 0)
                                .map((error, i) => (
                                    <span
                                        id={`num_cards_${i}_error`}
                                        key={i}
                                        className="govuk-error-message"
                                    >
                                        {error}
                                    </span>
                                ))}
                            <input
                                className="govuk-input govuk-input--width-4"
                                id={`${this.props.presenter.paramName}_num_cards`}
                                name={`${this.props.presenter.paramName}[num_cards]`}
                                value={this.state.numCards}
                                aria-label={this.screenReaderToDicateOrderSummaryBoxText()}
                                type="text"
                                onChange={this.handleNewTotal}
                            />
                        </div>
                        <div className="govuk-form-group">
                            <input
                                type="hidden"
                                name={this.props.presenter.csrfParam}
                                value={this.props.presenter.csrfToken}
                                readOnly
                            />
                        </div>
                        <button
                            id="continue"
                            type="submit"
                            className="govuk-button"
                        >
                            Continue
                        </button>
                    </form>
                </div>
                <div className="govuk-grid-column-one-third">
                    <details className="govuk-details">
                        <summary className="govuk-details__summary">
                            <span className="govuk-details__summary-text">
                                Need help
                            </span>
                        </summary>
                        <div className="govuk-details__text">
                            <h2
                                className="heading-medium"
                                style={{ marginTop: 0 }}
                            >
                                Call DVLA
                            </h2>
                            <p>
                                {this.props.presenter.phoneNumber}
                                <br />
                                {this.props.presenter.openingTimes}
                            </p>
                            <a
                                href={this.props.presenter.callCharges}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Find out about call charges
                            </a>
                        </div>
                    </details>
                    <OrderSummary
                        runningTotal={this.state.runningTotal}
                        numCards={this.state.numCards}
                        validatedPurchaseVolume={
                            this.state.validatedTachoDisplayNum
                        }
                    />
                    <input
                        className="focus-hidden"
                        tabIndex="-1"
                        value="updating"
                        readOnly
                        id="calculating"
                        aria-hidden="true"
                    />
                </div>
            </div>
        );
    }
}

CardsPage.propTypes = {
    presenter: PropTypes.shape({
        errors: PropTypes.object,
        callCharges: PropTypes.string,
        phoneNumber: PropTypes.string,
        openingTimes: PropTypes.string,
        numCards: PropTypes.string,
        transactionCost: PropTypes.string,
        question: PropTypes.string,
        questionHint: PropTypes.shape({
            __html: PropTypes.string,
        }),
        action: PropTypes.string,
        paramName: PropTypes.string,
        csrfParam: PropTypes.string,
        csrfToken: PropTypes.string,
    }).isRequired,
};
