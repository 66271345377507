/* eslint no-console:0 */
import {
    GOVUKInitAll,
    HMRCInitAll,
    ClearCookies,
} from 'dvla-external-frontend';

require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);

// ensure images are included in the compiled pack
require.context('./images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack
require.context('dvla-external-frontend/dist/media', true);

// Support component names relative to this directory:
const componentRequireContext = require.context('./components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();
